var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "not-found-container" }, [
    _c("div", {
      staticClass: "tips",
      domProps: { textContent: _vm._s(_vm.tips) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }