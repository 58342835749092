<template>
  <div class="not-found-container">
    <div class="tips" v-text="tips"></div>
  </div>
</template>
<script>
export default {
  name: 'notFound',
  data () {
    return {
      tips: '页面开发中...'
    }
  }
}
</script>
<style scoped lang="scss">
  .not-found-container {
    font-size: 25px;
    .tips {
      margin-top: 200px;
    }
  }
</style>
